import { Card as BootstrapCard } from "react-bootstrap";

export function Card ({ title, href, button: Button, body: Body }) {
    return (
        <div style={{ paddingBottom: '35px' }}>
            {href ? (
                <a href={href ? href : "#"} style={{ textDecoration: 'none', }}>
                    <BootstrapCard style={{ backgroundColor: 'rgb(49, 49, 49)', color: '#eee', borderColor: "rgb(49, 49, 49)" }}>
                        <BootstrapCard.Body>
                            <BootstrapCard.Title style={{ padding: '0', margin: '0' }}>{title}</BootstrapCard.Title>

                            {Body && <div style={{ paddingTop: '15px' }}>{Body}</div>}

                            {Button && <div style={{ paddingTop: '15px' }}>{Button}</div>}
                        </BootstrapCard.Body>
                    </BootstrapCard>
                </a>
            ) : (
                <BootstrapCard style={{ backgroundColor: 'rgb(49, 49, 49)', color: '#eee', borderColor: "rgb(49, 49, 49)" }}>
                    <BootstrapCard.Body>
                        <BootstrapCard.Title style={{ padding: '0', margin: '0' }}>{title}</BootstrapCard.Title>

                        {Body && <div style={{ paddingTop: '15px' }}>{Body}</div>}

                        {Button && <div style={{ paddingTop: '15px' }}>{Button}</div>}
                    </BootstrapCard.Body>
                </BootstrapCard>
            )}
        </div>
    );
}