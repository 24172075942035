import { isMobile } from "react-device-detect";
import { Footer, Navbar } from "../components";

export function PurchasedTicketsPage () {
    const query = new URLSearchParams(window.location.search);

    const ID = query.get('id');

    return (
        <div>
            <Navbar />

            <div className="container text-center" style={{ paddingBottom: '45px' }}>
                <h1 style={{ padding: '45px', paddingTop: '75px', paddingBottom: '25px' }}>Thank you for your purchase!</h1>

                <p style={{ fontSize: '20px' }}>We look forward to seeing you at the event, please check your email for tickets & event details.</p>

                <a href={'/tickets/print?id=' + ID} style={{ color: 'black' }}>
                    <p style={{ fontSize: '24px' }}>Print Tickets</p>
                </a>
            </div>

            <Footer sticky={isMobile ? false : true} />
        </div>
    );
}