import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import Config from '../config';

const sitePassword = Config.password;

export function SitePasswordPage ({ children }) {
    const [SiteUnlocked, setSiteUnlocked] = useState(false);

    const [PasswordInput, setPasswordInput] = useState('');

    function validatePassword (e) {
        e.preventDefault();

        if (PasswordInput != sitePassword) {
            alert('Invalid password!');
        } else {
            setSiteUnlocked(true);

            localStorage.setItem('site-unlocked', JSON.stringify(true));
        }
    }

    useEffect(function () {
        const siteUnlockedStorage = localStorage.getItem('site-unlocked');

        if (siteUnlockedStorage && JSON.parse(siteUnlockedStorage))
            setSiteUnlocked(true);
    }, []);

    return !SiteUnlocked ? (
        <div style={{ height: '100vh', width: '100vw', overflow: "hidden", backgroundColor: 'white' }}>
            <div style={{ textAlign: 'center', padding: '65px' }}>
                <h1 className="h3">This site is currently password protected.</h1>

                <p>To enter please enter the password.</p>

                <Form style={{ width: '600px', margin: 'auto', textAlign: 'left', paddingTop: '25px' }} onSubmit={validatePassword}>
                    <Form.Label style={{ fontSize: '14px' }}>Site Password</Form.Label>

                    <Form.Control type='text' value={PasswordInput} onChange={e => setPasswordInput(e.target.value)} />

                    <hr />

                    <Button style={{ width: '100%', backgroundColor: 'black', color: '#eee' }} onClick={validatePassword}>
                        Enter Site
                    </Button>
                </Form>
            </div>

            <p style={{ position: 'absolute', bottom: '0', width: '100%', textAlign: 'center' }}>Designed & Maintained by Breaking Point Software</p>
        </div>
    ) : (
        children
    );
};