import { useEffect, useState } from 'react';
import { Footer, Navbar } from '../components';
import { isMobile } from 'react-device-detect';

export function GalleryPage () {
    // const galleryItems = ['15.jpg', '12.jpg', '11.jpg', '13.jpg', '14.jpg', '10.jpg', '8.jpg', '4.jpg', '5.jpg', '2.jpg', '7.jpg', '1.jpg', '3.jpg'];

    const [galleryItems, setgalleryItems] = useState([]);

    const rows = Math.round(galleryItems.length / 4);

    const columns = 4;

    const [Count, setCount] = useState(0);

    useEffect(function () {
        fetch('https://api.americansteelfightingleague.com/cdn/count').then(async data => {
            const { count, error, files } = await data.json();

            if (error) return alert('Error fetching images.');

            setCount(count);

            let items = [];

            for (let i = 0; i < count; i++) {
                items.push(`https://api.americansteelfightingleague.com/cdn/${files[i]}`);
            }

            setgalleryItems(items);
        });
    }, []);

    return (
        <div>
            <Navbar />

            <div className="container text-center">
                <h1 className='h1' style={{ padding: '45px' }}>Gallery</h1>

                {isMobile ? (
                    <div>
                        {galleryItems.map((item) => {
                            return (
                                <div>
                                    <img src={item} style={{ width: '100%', marginBottom: '25px' }} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div style={{ display: 'flex' }}>
                        {[...Array(columns)].map((x, i) =>
                            <div style={{ width: '100%' }}>
                                {[...Array(columns)].map((x, z) =>
                                    <div style={{ width: `calc(100%)`, padding: '15px' }}>
                                        {
                                            galleryItems[i + (z * 3) + z] &&
                                            <a href={galleryItems[i + (z * 3) + z]} target="_blank">
                                                <img
                                                    src={galleryItems[i + (z * 3) + z]}
                                                    style={{
                                                        width: `100%`
                                                    }}
                                                />
                                            </a>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}