import { Button, Container } from "react-bootstrap";
import { Card, Footer, Navbar } from "../components";

import Config from '../config';
import { isMobile } from "react-device-detect";

export function TicketsPage () {
    const generalCheckout = `${Config.API}/tickets/purchase`;
    const VIPCheckout = `${generalCheckout}?vip=true`;

    return (
        <div>
            <Navbar />

            <div style={{ padding: '45px', paddingBottom: '25px', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div>
                    <h1 className='h1'>Tickets</h1>

                    <p style={{ padding: '6px', backgroundColor: 'rgb(49, 49, 49)', width: isMobile ? '300px' : '500px', borderRadius: '6px', color: '#eee', marginTop: '15px' }}>You are purchasing tickets for the <a href='/events' style={{ color: 'white' }}><u>Husaria Cup</u></a></p>
                </div>
            </div>

            <Container>
                <div className="text-center" style={{ paddingBottom: '45px', width: "90%", margin: 'auto' }}>
                    <div className="row">
                        <div className="col-md-2" />
                        <div className="col-md-4">
                            <Card
                                title='General Admission'
                                button={(
                                    <div>
                                        <Button href={generalCheckout} style={{ width: '100%', borderRadius: '0', backgroundColor: 'gray', borderColor: 'gray' }}>Purchase Ticket</Button> </div>
                                )}
                                body={(
                                    <div style={{ padding: '15px' }}>
                                        <h1>20.00$</h1>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <Card
                                title='VIP Admission'
                                button={(
                                    <div>
                                        <Button href={VIPCheckout} style={{ width: '100%', borderRadius: '0', backgroundColor: 'gray', borderColor: 'gray' }}>Purchase Ticket</Button>
                                    </div>
                                )}
                                body={(
                                    <div style={{ padding: '15px' }}>
                                        <h1>35.00$</h1>
                                    </div>
                                )}
                            />
                        </div>
                    </div>

                    <p style={{ margin: '0', padding: "0", fontSize: '16px', marginTop: '10px', fontStyle: 'italic', fontWeight: '600' }}>By clicking Purchase, you agree to our terms and conditions.</p>
                </div>
            </Container>

            <Footer />
        </div >
    );
}