import { useEffect } from "react";
import { Helmet } from 'react-helmet';

export function PrintTicketsPage () {
    const query = new URLSearchParams(window.location.search);

    const ID = query.get('id');

    useEffect(function () {
        if (!ID) return window.location.href = '/';

        window.print();
    }, []);

    return (
        <div>
            <Helmet>
                <style>
                    {`
                        body {
                            background-color: white !important;
                        }
                    `}
                </style>
            </Helmet>

            <div
                style={{
                    width: "100%",
                    // backgroundColor: 'rgb(49, 49, 49)',
                    padding: '0',
                    margin: '0',
                    borderRadius: '8px 8px 0 0'
                }}
            >
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <img
                        src="/logo192.png"
                        style={{ width: '140px' }}
                    />

                    <h1 style={{ color: "#eee", padding: '0', margin: '0', color: 'black' }}>
                        American Steelfighting League
                    </h1>

                    <h1 style={{ color: "#eee", padding: '0', margin: '0', color: 'black' }}>
                        Husaria Cup 2024
                    </h1>

                    <p style={{ color: "#eee", padding: '0', margin: '0', fontSize: '18px', color: 'black', paddingTop: '10px' }}>
                        Aug. 10 at the Split Rock Hotel
                    </p>
                </div>
            </div>

            <div
                style={{ width: "100%", /*backgroundColor: 'rgb(128, 128, 128)',*/ textAlign: 'center', borderRadius: '0 0 8px 8px' }}
            >
                <div>
                    <u style={{ color: 'black' }}>
                        <h1>Tickets</h1>
                    </u>

                    {ID && <img
                        src={`https://ticketing.americansteelfightingleague.com/qr-codes/${ID}.png`}
                        style={{ width: '240px' }}
                    />}
                </div>

                <div style={{ paddingTop: "20px" }}>
                    <u style={{ color: 'black' }}>
                        <h1>Directions</h1>
                    </u>

                    <a href="https://maps.app.goo.gl/nyxvRBpHW2Twf7zo8" style={{ color: 'black' }}>
                        <p style={{ fontSize: '22px', margin: '0', padding: "0", paddingTop: "10px" }}>
                            Pocono Mountains, 428 Moseywood Rd, Lake Harmony, PA 18624
                        </p>
                    </a>
                </div>

                <div style={{ paddingTop: '20px', paddingBottom: "20px", textAlign: 'center' }}>
                    <u style={{ color: 'black' }}>
                        <h1>Event Information</h1>
                    </u>

                    <p style={{ width: "80%", margin: 'auto', padding: '0', fontSize: '16px', color: 'black' }}>
                        Come see the American Steelfighting League Husaria Cup for these epic battles with Knights in full armor. Come, watch the defenders fight the dragons in this summertime extravaganza, this time with air conditioning.
                    </p>
                </div>
            </div>
        </div>
    );
}