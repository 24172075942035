import { Button } from "react-bootstrap";

export function Header ({ height, width }) {
    function leftClick (e) {
        e.preventDefault();
    }

    function rightClick (e) {
        e.preventDefault();
    }

    return (
        <div
            style={{
                height: height,
                width: width,
                position: 'relative'
            }}
        >
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: "8px",
                    backgroundColor: 'black',
                    color: '#eee',
                    display: 'flex',
                    backgroundImage: "url(\"/pictures/15.jpg\")",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 20%',
                }}
            />

            <div
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: "8px",
                    backgroundColor: 'rgba(0, 0, 0, 0.35)',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
            />

            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%'
                }}
            >
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', color: '#eee' }}>
                    <a href='#' onClick={leftClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                        <div>
                            <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                <i className="fas fa-chevron-left"></i>
                            </h1>
                        </div>
                    </a>

                    <div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="text-center">
                            <h1 className="h5">
                                American Steelfighting League Presents
                            </h1>

                            <h1 className="h2">
                                Husaria Cup
                            </h1>

                            <h1 className="h5">
                                Aug. 10 at the Split Rock Hotel
                            </h1>

                            <Button variant='secondary' href='/tickets' style={{ marginTop: '5px' }}>
                                Purchase Tickets
                            </Button>
                        </div>
                    </div>

                    <a href='#' onClick={rightClick} style={{ width: '10%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#eee' }}>
                        <div>
                            <h1 style={{ padding: '0', margin: '0' }} className="h2">
                                <i className="fas fa-chevron-right"></i>
                            </h1>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}