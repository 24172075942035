import { Footer, Navbar } from "../components";

export function NotFoundPage () {
    return (
        <div>
            <Navbar />

            <div className="container text-center" style={{ height: "calc(100vh - 180px)" }}>
                <h1 style={{ padding: '45px', paddingTop: '75px', paddingBottom: '25px' }}>404 Error</h1>
                <p>We could not find the current page you are looking for.</p>
            </div>

            <Footer sticky />
        </div>
    );
}