import { Navbar, Footer, Card, Header } from '../components';

export function LandingPage () {
    const headerHeight = '250px';

    return (
        <div>
            <Navbar />

            <div className='container' style={{ height: headerHeight, marginTop: '25px' }}>
                <Header height='100%' width='100%' />
            </div>

            <div className='container' style={{ marginTop: '25px' }}>
                <h1 className='h3' style={{ paddingBottom: '25px' }}>Site Directory:</h1>

                <div className="row">
                    <div className="col-md-4">
                        <Card title='Upcoming & Past Events' href='/events' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Purchase Tickets' href='/tickets' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Media & Gallery Items' href='/gallery' />
                    </div>
                    {/* <div className="col-md-4">
                        <Card title='Fighters List' href='/fighters' />
                    </div>
                    <div className="col-md-4">
                        <Card title='Rules List' href='/rules' />
                    </div> */}
                    {/* <div className="col-md-4">
                        <Card title='Sponsors List' href='/sponsors' />
                    </div> */}
                    <div className="col-md-4">
                        <Card title='Make a Donation' href='/donate' />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}