import { Navbar as BoostrapNavbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Routes } from "../pages/routes";

import { isMobile } from 'react-device-detect';
import { useState } from "react";

export function Navbar () {
    const [ShowMenu, setShowMenu] = useState(false);

    return (
        ShowMenu ? (
            <div style={{ width: '100vw', backgroundColor: 'rgb(49, 49, 49)' }}>
                <Container>
                    <div style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
                        <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#eee' }}>
                            <img src='/logo192.png' style={{ height: '60px' }} />

                            <h1 style={{ fontSize: '16px', margin: '0', padding: '0', marginLeft: '10px' }}>American Steelfighting League</h1>
                        </a>

                        <a href='#' style={{ fontSize: '30px', color: '#eee', marginLeft: 'auto', width: '46px' }} onClick={() => setShowMenu(b => !b)}>
                            <i className="fas fa-bars"></i>
                        </a>
                    </div>

                    <div className="me-auto">
                        <div style={{ marginLeft: '0', paddingLeft: '0', marginTop: '0', paddingTop: '0', color: '#eee', paddingBottom: '10px' }}>
                            {Routes.map((route) => {
                                return !route.hidden && (
                                    <div style={{ padding: "5px", paddingLeft: '0' }}>
                                        <a href={route.path} style={{ textDecoration: 'none', color: '#eee' }}>
                                            <h1 className="h4">
                                                {route.label}
                                            </h1>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Container>
            </div>
        ) : (
            <BoostrapNavbar expand="lg" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(49, 49, 49)', height: isMobile ? '100px' : "180px" }}>
                {!isMobile ? (
                    <Container>
                        <BoostrapNavbar.Brand href="/" style={{ color: 'rgb(255, 255, 255)' }}>
                            <img src='/logo192.png' style={{ height: '120px' }} />
                        </BoostrapNavbar.Brand>

                        <div className="me-auto">
                            <BoostrapNavbar.Brand href="/" style={{ color: 'rgb(255, 255, 255)', fontSize: "22px" }}>American Steelfighting League</BoostrapNavbar.Brand>

                            <Nav style={{ marginLeft: '0', paddingLeft: '0', marginTop: '12px' }}>
                                {Routes.map((route) => {
                                    return !route.hidden && !route.rightAligned && (
                                        <Nav.Link href={route.path} style={{ color: 'rgb(255, 255, 255)', marginLeft: '0', paddingLeft: '0', paddingRight: '20px' }}>{route.label}</Nav.Link>
                                    );
                                })}
                            </Nav>
                        </div>

                        <Nav className="me-left" style={{ display: 'flex', alignItems: 'center' }}>
                            {Routes.map((route) => {
                                return !route.hidden && route.rightAligned && <Nav.Link href={route.path} style={{ color: 'rgb(255, 255, 255)', marginLeft: '0', paddingLeft: '0', paddingRight: '20px' }}>
                                    {route.icon ? <h1 style={{ color: 'rgb(255, 255, 255)', fontSize: "36px", margin: '0' }}>
                                        {route.icon}
                                    </h1> : route.label}
                                </Nav.Link>;
                            })}
                        </Nav>
                    </Container>
                ) : (
                    <Container>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#eee' }}>
                                <img src='/logo192.png' style={{ height: '60px' }} />

                                <h1 style={{ fontSize: '16px', margin: '0', padding: '0', marginLeft: '10px' }}>American Steelfighting League</h1>
                            </a>

                            <a href='#' style={{ fontSize: '30px', color: '#eee', marginLeft: 'auto', width: '46px' }} onClick={() => setShowMenu(b => !b)}>
                                <i className="fas fa-bars"></i>
                            </a>
                        </div>
                    </Container>
                )}
            </BoostrapNavbar>
        )
    );
}