import { SitePasswordPage } from './pages'

import { Routes } from './pages/routes'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const showPasswordPage = false

const router = createBrowserRouter(Routes)

function App() {
	return showPasswordPage ? (
		<div>
			<SitePasswordPage>
				<RouterProvider router={router} />
			</SitePasswordPage>
		</div>
	) : (
		<div>
			<RouterProvider router={router} />
		</div>
	)
}

export default App
